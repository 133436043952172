<template>
  <div style="height: 100%" v-loading="loading">
    <div v-if="!loading">
      <div class="title">
        <div class="header">
          {{ is_next_day ? "明" : "今" }}天是{{
            `${this.date.getFullYear()}年${
              this.date.getMonth() + 1
            }月${this.date.getDate()}日`
          }}
          <br />
          那年今日，爬爬经历了：
        </div>
      </div>
      <div class="content">
        <div class="card" v-for="record in that_day_data" :key="record.year">
          <div class="time-mood-container">
            <div>
              <img :src="getMoodImage(record.mood)" alt="" class="mood-img" />
            </div>
            <div>
              <div class="year">{{ record.year }}年</div>
              <div
                class="mood-text"
                :style="{ color: getColorById(record.mood) }">
                {{ getMoodById(record.mood) }}
              </div>
            </div>
          </div>
          <div class="tags-container">
            <div
              class="tag"
              v-for="tag in record.tags"
              :key="tag"
              :style="{ borderColor: getColorById(record.mood) }">
              {{ getTagById(tag) }}
            </div>
          </div>
          <div class="photos-container" v-if="record.assets.length != 0">
            <el-carousel
              height="250px"
              :arrow="record.assets.length == 1 ? 'never' : 'always'"
              :indicator-position="
                record.assets.length == 1 ? 'none' : 'inside'
              ">
              <el-carousel-item v-for="asset in record.assets" :key="asset">
                <el-image
                  :src="getAssetImage(asset)"
                  :preview-src-list="[getAssetImage(asset)]"
                  fit="cover">
                  <div slot="placeholder">图片加载中...</div>
                </el-image>
              </el-carousel-item>
            </el-carousel>
            <el-divider />
            <div v-if="is_comments_loading" class="comment-load">
              评论加载中...
            </div>
            <div class="comment" v-else>
              <div
                class="comment-item"
                v-for="comment in record.all_comments"
                :key="comment._id"
                :style="{
                  textAlign: comment.user === 'mxx' ? 'left' : 'right',
                }">
                <div class="comment-time">
                  {{ getDateStr(comment.action_date) }}
                </div>
                <div class="comment-text">{{ comment.text }}</div>
              </div>
            </div>
            <div class="comment-editor">
              <el-input
                plain
                type="textarea"
                :autosize="{ minRows: 1, maxRows: 4 }"
                v-model="record.comment"
                placeholder="请输入评论" />
              <el-button-group style="width: 100%; padding-top: 5px">
                <el-button
                  id="mxx"
                  size="small"
                  icon="el-icon-coffee-cup"
                  @click="submitComment(record, 'mxx')"
                  :disabled="submit_loading"
                  :loading="submit_loading">
                  小师姐提交
                </el-button>
                <el-button
                  id="zxf"
                  size="small"
                  @click="submitComment(record, 'zxf')"
                  :disabled="submit_loading"
                  :loading="submit_loading">
                  小师弟提交
                  <i class="el-icon-potato-strips" />
                </el-button>
              </el-button-group>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="fixed-bottom-bg">
          <div class="fixed-bottom-text" @dblclick="nextDay()">
            That Day那年今日-轩萱小师姐专属
            <div class="busuanzi">
              <span id="busuanzi_container_site_pv" style="display: none">
                总访问
                <span id="busuanzi_value_site_pv" />
                次
              </span>
            </div>
          </div>
        </div>
        <img class="fixed-bottom-img" src="@/assets/koutu.png" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import bsz_script from "busuanzi.pure.js";
// 读取一个外部json文件
import daylio_data from "@/assets/daylio/backup.json";

export default {
  name: "ThatDay",
  data() {
    return {
      loading: true,
      date: new Date(),
      that_day_data: [],
      color_dict: [
        { mood_id: 1, color: "#5ea56c" },
        { mood_id: 2, color: "#9acb4a" },
        { mood_id: 3, color: "#eebc47" },
        { mood_id: 4, color: "#e8913d" },
        { mood_id: 5, color: "#dc353a" },
      ],
      is_comments_loading: true,
      is_next_day: false,
      submit_loading: false,
    };
  },
  mounted() {
    this.getDate();
    bsz_script.fetch();
  },
  methods: {
    async getDate() {
      const time_url =
        "https://cn.apihz.cn/api/time/getapi.php?id=10001404&key=2e5f948149b7c2d7ac10c6aa7c200bde&type=2";
      try {
        const res = await axios.get(time_url);
        this.date = new Date(res.data.msg);
        this.getDaylioData();
      } catch (err) {
        console.log(err);
      }
    },
    async getDaylioData() {
      // 找到daylio_data.dayEntries（一个列表，里面每一项是个字典，包含了year、month、day、mood，我要找到year、month、day都和今天一样的那一项）
      // 找到和今天一样的多项
      this.that_day_data = daylio_data.dayEntries
        .filter((item) => {
          return (
            item.month === this.date.getMonth() &&
            item.day === this.date.getDate()
          );
        })
        .sort((a, b) => b.year - a.year);
      this.loading = false;
      this.getAllComments();
    },
    async getAllComments() {
      this.is_comments_loading = true;
      const month = this.date.getMonth() + 1;
      const day = this.date.getDate();
      // 获取所有评论
      const get_comments_url = `https://ppdays.cc/.netlify/functions/getComments?month=${month}&day=${day}`;
      try {
        const res = await axios.get(get_comments_url);
        this.that_day_data.forEach((record) => {
          record.all_comments = res.data.results.filter(
            (item) => item.year === record.year
          );
        });
      } catch (err) {
        this.$message({
          message: "评论加载失败",
          type: "error",
        });
      }
      this.is_comments_loading = false;
    },
    getMoodById(mood) {
      return daylio_data.customMoods.find((item) => item.id === mood)
        .custom_name;
    },
    getColorById(mood) {
      return this.color_dict.find((item) => item.mood_id === mood).color;
    },
    getMoodImage(mood) {
      return require(`@/assets/svg/moods/${mood}.svg`);
    },
    getTagById(tag) {
      return daylio_data.tags.find((item) => {
        return item.id === tag;
      }).name;
    },
    getAssetImage(asset) {
      let asset_data = daylio_data.assets.find((item) => item.id === asset);
      if (asset_data) {
        const filename = asset_data.checksum;
        const date = new Date(asset_data.createdAt);
        const img_url = `https://ppdays.cc/assets/photos/${date.getFullYear()}/${
          date.getMonth() + 1
        }/${filename}.jpg`;
        return img_url;
      }
    },

    getDateStr(date) {
      return new Date(date).toLocaleString();
    },
    async submitComment(record, user) {
      if (record.comment === undefined || record.comment === "") {
        this.$message({
          message: "评论不能为空",
          type: "warning",
        });
        return;
      }
      this.submit_loading = true;
      const data = {
        user: user,
        text: record.comment,
        year: record.year,
        month: record.month + 1,
        day: record.day,
      };
      const post_comment_url =
        "https://ppdays.cc/.netlify/functions/postComment";
      try {
        await axios.post(post_comment_url, data);
        this.$message({
          message: "评论成功",
          type: "success",
        });
        record.comment = "";
        this.submit_loading = false;
        this.getAllComments();
      } catch (err) {
        console.log(err);
        this.$message({
          message: "评论失败",
          type: "error",
        });
      }
    },
    nextDay() {
      // 判断有没有到22：14后
      const currentDate = new Date(this.date);
      const targetDate = new Date();
      targetDate.setHours(22, 14, 0, 0);
      if (currentDate >= targetDate) {
        if (this.is_next_day) {
          this.date.setDate(this.date.getDate() - 1);
        } else {
          this.date.setDate(this.date.getDate() + 1);
        }
        this.getDaylioData();
        this.is_next_day = !this.is_next_day;
        // 过渡跳转到顶部
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-size: 18px;
  text-align: center;
  padding: 15px 20px 20px 20px;
  .header {
    color: #666;
  }
  .end {
    font-size: 14px;
    color: #666;
  }
}

.content {
  text-align: center;
  padding-bottom: 170px;
  .card {
    background-color: #fff;
    border-radius: 10px;
    margin: 0px 20px 15px 20px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
    .time-mood-container {
      padding: 15px 5px 0 5px;
      display: flex;
      justify-content: flex-start;
      .mood-img {
        width: 53px;
        height: 53px;
        margin: 0px 10px;
      }
      .year {
        text-align: left;
        font-size: 16px;
      }
      .mood-text {
        text-align: left;
        font-size: 20px;
        // 加粗
        font-weight: bold;
      }
    }
    .tags-container {
      text-align: left;
      margin: 5px 15px 10px 15px;
      display: flex;
      flex-wrap: wrap;
      overflow-x: auto;
      .tag {
        font-size: 14px;
        // 边框
        border: 1px solid;
        // 圆角
        border-radius: 5px;
        padding: 1px 5px;
        margin-right: 5px;
        margin-bottom: 5px;
      }
    }
    .photos-container {
      padding: 0px 20px 15px 20px;
      .el-image {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
    .comment-load {
      font-size: 16px;
      color: #666;
      padding-bottom: 10px;
    }
    .comment {
      .el-divider {
        margin: 10px 0;
      }
      .comment-item {
        padding-bottom: 5px;
        .comment-time {
          font-size: 14px;
          color: #666;
        }
        .comment-text {
          font-size: 16px;
          color: #333;
        }
      }
    }
    .comment-editor {
      // 浅色主题色：mxx#efe5fe zxf#c9e2f5
      // 深色主题色：mxx#063559 zxf#1c1c1e
      #mxx {
        width: 50%;
        color: #333;
        background: linear-gradient(90deg, #efe5fe 80%, #8797b5);
      }

      #zxf {
        width: 50%;
        color: #ddd;
        background: linear-gradient(270deg, #063559 80%, #8797b5);
      }
    }
  }
}
.fixed-bottom-img {
  height: 170px;
  position: fixed;
  bottom: 0;
  left: 0px;
  // 位于最上层
  z-index: 999;
  mask-image: linear-gradient(to left, transparent, black 10%, black);
}
.fixed-bottom-bg {
  height: 52px;
  position: fixed;
  bottom: 0;
  left: 0px;
  width: 100%;
  // 渐变背景色
  // background: linear-gradient(0deg, #fff, #fff 80%, rgba(255, 255, 255, 0));
  background: #f8f8f8;
  border-top: 1px solid #ccc;
  z-index: 999;
  .fixed-bottom-text {
    padding: 6px 0 0 155px;
    font-size: 13px;
    color: #666;
  }
}

/* 检测暗黑模式 */
@media (prefers-color-scheme: dark) {
  .title {
    .header {
      color: #ddd !important;
    }
    .end {
      color: #aaa !important;
    }
  }
  .content {
    .card {
      background-color: #1c1c1e;
      box-shadow: none;
      color: #ddd !important;
      .year {
        color: #fff !important;
      }

      .comment-load {
        color: #aaa !important;
      }
      .comment {
        .comment-time {
          color: #aaa !important;
        }
        .comment-text {
          color: #ddd !important;
        }
      }
      /deep/input::-webkit-input-placeholder {
        color: #bbb !important;
      }
      // 改变input框背景颜色
      /deep/.el-textarea__inner {
        background-color: transparent !important;
        border-color: #888 !important;
        color: #bbb !important;
      }
      #mxx,
      #zxf {
        border-color: #333;
      }
    }
  }
  .fixed-bottom-bg {
    background: #0e0e0f;
    border-top: 1px solid #333;
    .fixed-bottom-text {
      color: #aaa;
    }
  }
}
</style>
